import { AudioTrack, VideoTrack } from 'twilio-video'

export const NOTARY_VIDEO_PREFIX = 'notaryVideo-'
export const PLOTTER_VIDEO_PREFIX = 'plotterVideo-'
export const NOTARY_AUDIO_PREFIX = 'notaryAudio-'

export const MAX_TRACK_PUBLISH_TIME_MSEC = 5000

export const attachTrack = (track: any, domEl: any): HTMLVideoElement => {
    const trackEl = (track as VideoTrack | AudioTrack).attach()
    domEl.appendChild(trackEl)
    return trackEl as HTMLVideoElement
}

export const getNumSecondsSinceJan1970 = (): number => {
    return Math.floor(Date.now() / 1000)
}

export const toProperCase = (str?: string): string => {
    if (!str) return str
    const words = str.split(' ')
    const results = []
    for (let i = 0; i < words.length; i++) {
        const letter = words[i].charAt(0).toUpperCase()
        results.push(letter + words[i].slice(1)?.toLowerCase())
    }
    return results.join(' ')
}

// Keep in sync with SessionState in aven_backend/src/entity/applicantNotaryAssignment.ts
export enum SessionState {
    viewingDocuSign = 'viewingDocuSign',
    viewingFaceVideos = 'viewingFaceVideos',
    performingFrontIdVerification = 'performingFrontIdVerification',
    performingBackIdVerification = 'performingBackIdVerification',
    sessionCompleteSuccess = 'sessionCompleteSuccess',
    // Applicant can continue, notary has a bit more work before the session is 'completeSuccess'
    ejectApplicantFromCall = 'ejectApplicantFromCall',
    sessionCompleteFailure = 'sessionCompleteFailure',
    // Applicant session is a failure, but they can return to be re-notarized from the beginning
    sessionPartialFailure = 'sessionPartialFailure',

    // These are temporary states that indicate an action in progress, generally take < 5 seconds to transition
    takeFrontIDPicture = 'takeFrontIDPicture',
    takeBackIDPicture = 'takeBackIDPicture',
    refreshDocuSign = 'refreshDocuSign',

    // RIN Only Modes
    viewingDeedOfTrust = 'viewingDeedOfTrust',
    gatherPlotterSignature = 'gatherPlotterSignature',
}

export const SessionAgent = {
    notary: 'notary',
    applicant: 'applicant',
}

// Keep in sync with ApplicantType in aven_backend/src/entity/applicant.ts
export enum ApplicantType {
    primary = 'primary', // i.e. regular applicant
    coApplicant = 'coApplicant', // is underwritten along with primary
    coOwner = 'coOwner', // purely for signature purposes
    livingTrustMember = 'livingTrustMember', // purely for signature purposes
}
